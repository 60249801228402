<template>
  <div>
    <BaseElForm
      ref="formRef"
      :model="curCartItem"
      :rules="formRules"
      label-position="top"
      class="form-wrapper"
    >
      <template v-if="!loading.coreFlow">
        <BaseElFormItem :label="displayData.formItemLabel.serviceType">
          <BaseElSelect
            v-model="curCartItem.serviceType"
            testName="formData_type"
            value-key="id"
            placeholder="請選擇"
            no-data-text="暫無數據"
            @change="onServiceTypeChange"
          >
            <BaseElSelectOption
              v-for="item in serviceTypeConfigList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.serviceUnit" prop="serviceUnit">
          <template slot="label">
            <FormItemTooltipLabel :label="displayData.formItemLabel.serviceUnit" :tooltipWidth="200" style="display: flex !important">
              <ol>
                <li>1. 由所有服務人員分配：由分店內所有服務人員分配</li>
                <br>
                <li>2. 由不指定人員分配：由綁定在不指定人員中之服務人員分配</li>
              </ol>
            </FormItemTooltipLabel>
          </template>
          <div>
            <BaseElSelect
              v-model="curCartItem.serviceUnit"
              testName="formData_serviceUnit"
              value-key="id"
              @change="onServiceUnitChange"
              @clear="onServiceUnitChange"
            >
              <BaseElSelectOption
                v-for="unit in serviceUnitIOptions"
                :key="unit.id"
                :value="unit"
                :label="unit.name"
              />
            </BaseElSelect>
            <p
              v-show="serviceUnitSelectHint.content"
              :style="`color: ${serviceUnitSelectHint.color}`"
            >
              {{ serviceUnitSelectHint.content }}
            </p>
          </div>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.service" :label="displayData.formItemLabel.service" prop="service">
          <BaseElSelect
            v-model="curCartItem.service"
            testName="formData_service"
            value-key="id"
            @change="onServiceChange"
            @clear="onServiceChange"
          >
            <BaseElSelectOption
              v-for="service in displayServiceOptions"
              :key="service.id"
              :value="service"
              :label="service.name"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.subService" :label="displayData.formItemLabel.subService" prop="subService">
          <BaseElSelect
            v-model="curCartItem.subService"
            testName="formData_subService"
            value-key="id"
            @change="onSubServiceChange"
          >
            <BaseElSelectOption
              v-for="service in displaySubServiceOptions"
              :key="service.id"
              :label="service.name"
              :value="service"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.resources" :label="displayData.formItemLabel.resources" prop="resources">
          <BaseElSelect
            v-model="curCartItem.resources"
            testName="formData_service"
            value-key="id"
            multiple
            collapse-tags
            :multiple-limit="1"
            @change="onResourceChange"
          >
            <BaseElSelectOption
              v-for="service in displayResourceOptions"
              :key="service.id"
              :value="service"
              :label="service.name"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.attachedServices" :label="displayData.formItemLabel.attachedServices" prop="attachedServices">
          <BaseElSelect
            v-model="curCartItem.attachedServices"
            multiple
            collapse-tags
            value-key="id"
            @change="onAttachedServicesChange"
          >
            <BaseElSelectOption
              v-for="service in displayAttachServiceOptions"
              :key="service.id"
              :value="service"
              :label="service.name"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.bookingTime" :label="displayData.formItemLabel.bookingTime">
          <BaseElSelect
            v-model="curCartItem.bookingTime"
            testName="bookingTime"
            placeholder="請選擇"
          >
            <BaseElSelectOption
              v-for="(item, index) in displayBookingTimeOptions"
              :key="index"
              :label="item"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.resourceBookingTime">
          <template slot="label">
            <FormItemTooltipLabel :label="displayData.formItemLabel.resourceBookingTime">
              使用設備的起始時間與服務項目開<br>
              始時間一致。可調整此欄位的數<br>
              值，提前結束設備使用的時間
            </FormItemTooltipLabel>
          </template>
          <!-- <div
          v-if="!formData.totalTime"
          class="text-gray-100"
        >
          請先選擇預估服務時數
        </div> -->
          <BaseElSelect
            v-model="curCartItem.resourceBookingTime"
            testName="formData_resourceTotalTime"
            placeholder="請選擇"
          >
            <BaseElSelectOption
              v-for="(item, index) in displayBookingTimeOptions"
              :key="index"
              :label="item"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.isOverTIme" :label="displayData.formItemLabel.isOverTime">
          <BaseElRadioGroup v-model="curCartItem.isOverTime" @change="onIsOverTimeChange">
            <BaseElRadio :label="true">是</BaseElRadio>
            <BaseElRadio :label="false">否</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.date" :label="displayData.formItemLabel.date" prop="date">
          <el-date-picker
            ref="datePickerRef"
            v-model="curCartItem.date"
            editable
            :picker-options="datePickerOptions"
            type="date"
            placeholder="選擇日期"
            @focus="getDateOptions"
            @change="onDateChange"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showControl.formItem.time.timeSelect || showControl.formItem.time.overTimeSelect"
          :label="displayData.formItemLabel.time"
          prop="time"
        >
          <!-- <div v-if="!formData.date" class="text-gray-100">請先選擇預約日期</div> -->
          <BaseElSelect
            v-if="showControl.formItem.time.timeSelect"
            v-model="curCartItem.time"
            testName="formData_time"
            placeholder="請選擇"
            no-data-text="無數據"
            @change="onTimeChange"
          >
            <BaseElSelectOption
              v-for="item in displayAvailableTimes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>

          <BaseElSelect
            v-if="showControl.formItem.time.overTimeSelect"
            v-model="curCartItem.time"
            testName="formData_time"
            placeholder="請選擇"
            no-data-text="無數據"
            @change="onTimeChange"
          >
            <BaseElSelectOption
              v-for="item in displayOvertimeTimeOptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.classTickets" prop="classTickets">
          <template #label>
            <div class="flex flex-col" style="gap: 4px">
              <FormItemTooltipLabel :label="displayData.formItemLabel.classTickets">
                <p>{{ displayData.formItemTooltip.classTicket }}</p>
              </FormItemTooltipLabel>
              <p v-if="showControl.formItemHint.classTicket" class="text-sm text-gray-60">{{ displayData.formItemHint.classTicket }}</p>
            </div>
          </template>
          <BaseElSelect
            v-model="curCartItem.classTickets"
            testName="formData_service_classTicket"
            value-key="id"
            multiple
            :multiple-limit="1"
            @change="onClassTicketChange"
          >
            <BaseElSelectOption
              v-for="classTicket in displayClassTicketOptions"
              :key="classTicket.id"
              :label="classTicket.label"
              :disabled="classTicket.disabled"
              :value="classTicket"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </template>
    </BaseElForm>
  </div>
</template>

<script>
import { notSpecifyRulesConfig, serviceTypeConfig } from '@/config/reservation'
import { usePermissions } from '@/use/permissions'
import { appointmentModeType, useCreateAappointment } from '@/use/useCreateAppointment'
import { omit, map, get, includes, cloneDeep, filter, find, uniq, intersection, forEach, reduce, findIndex, isEmpty } from 'lodash'
import { computed, defineComponent, reactive, ref, nextTick, onMounted, watch } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { FindService } from '@/api/service'
import { useShop } from '@/use/shop'
import { generateNumbers, sortOrder } from '@/utils/helper'
import { generateFormatPeriod } from '@/utils/time'
import { formatDate, getMonth, thisMonth } from '@/utils/date'

import { FindServiceUnit } from '@/api/serviceUnit'
import { apiFormatAdaptor } from '@/utils/api'
import dayjs from '@/lib/dayjs'
import { GetAvailableTimes, CheckReservation, CalculateCheckoutCashbackLimit } from '@/api/reservation'
import { useVModel } from '@vueuse/core'
import { noEmptyRules } from '@/validation'
import store from '@/store'

export default defineComponent({
  name: 'AppointmentCoreFlowBlock',
  components: { FormItemTooltipLabel },
  props: {
    hideDateTimeSelector: {
      type: Boolean,
      default: false,
    },
    hideClassTicketSelector: {
      type: Boolean,
      default: false,
    },
    cartItemId: {
      type: String,
    },
    cartItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const {
      cart,
      context, resourceData, configData, memberData,
      getCartItemPreReservationsPayload,
      composeAllCartItemReservations,
      updateCartItemData,
      defaultSelectClassTicket,
      pruneAfterCartItem,
      clearAllFormFieldsValidate,
    } = useCreateAappointment()
    const datePickerRef = ref(null)
    const dataPlaceholder = reactive({
      services: [],
      attachedServices: [],
      availableDate: [],
      availableTimes: [],
      bookingTimes: [],
    })
    const loading = reactive({
      coreFlow: false,
    })

    const curCartItem = useVModel(props, 'cartItem', emit)
    const formRef = ref(null)
    const formRules = computed(() => {
      const rules = {
        service: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
      }

      const selectedService = get(curCartItem.value, 'service')

      // 如果服務有子項目
      if (get(selectedService, 'enableSubService')) {
        rules.subService = [noEmptyRules()]
      }

      // 如果服務必選堂票
      if (get(selectedService, 'enableClassTicket') && get(selectedService, 'requiredClassTicket')) {
        rules.classTickets = [{ type: 'array', required: true, trigger: ['change', 'blur'], message: '請選擇堂票' }]
      }

      if (curCartItem.value.isResourceService()) {
        rules.resources = [{ type: 'array', required: true, trigger: ['change', 'blur'], message: '請選擇服務設備' }]
      }

      return rules
    })
    const role = computed(() => get(store.getters, 'role'))

    const isShopOperation = computed(() => ['customRole:shopOperationDisableSchedule', 'shopOperation'].includes(role.value))
    // ?? 畫面顯示用的資料
    const displayData = computed(() => {
      const data = {
        formItemLabel: {
          serviceType: '服務性質',
          serviceUnit: '服務人員',
          service: '服務項目',
          subService: '子項目',
          resources: '服務設備',
          attachedServices: '附加服務',
          bookingTime: '預估服務時數(分鐘)',
          resourceBookingTime: '預估設備使用時數(分鐘)',
          isOverTime: '是否為加班時段',
          classTickets: '選擇堂票',
          date: '預約日期',
          time: '預約時段',
        },
        formItemTooltip: {
          classTicket: '僅顯示此會員擁有、並能用於已選擇的服務項目的堂票',
        },
        formItemHint: {
          classTicket: '多人預約時，選擇堂票僅會使用一張堂票',
        },
      }

      return data
    })

    // ?? 元件顯示控制
    const showControl = computed(() => {
      const controls = {
        formItem: {
          serviceUnit: true,
          service: true,
          subService: false,
          attachedServices: false,
          resources: false,
          bookingTime: false,
          resourceBookingTime: false,
          date: false,
          isOverTIme: true,
          time: {
            timeSelect: false,
            overTimeSelect: false,
          },
          classTickets: false,
        },
        formItemHint: {
          classTicket: false,
        },
      }

      if (context.peopleCount > 1) {
        controls.formItemHint.classTicket = true
      }

      const serviceType = curCartItem.value.serviceType
      if (serviceType === serviceTypeConfig.resourceService.value) {
        controls.formItem.serviceUnit = false
        controls.formItem.resources = true
      } else if (serviceType === serviceTypeConfig.humanAndResourceService.value) {
        controls.formItem.serviceUnit = true
        controls.formItem.resources = true
      }

      const selectedService = curCartItem.value.service
      if (selectedService) {
        controls.formItem.attachedServices = true
        if (get(selectedService, 'enableSubService')) {
          controls.formItem.subService = true
        }

        if (curCartItem.value.isResourceService()) {
          controls.formItem.bookingTime = false
          controls.formItem.resourceBookingTime = true
        } else {
          controls.formItem.bookingTime = true
          controls.formItem.resourceBookingTime = false
        }

        controls.formItem.date = true
      }

      if (curCartItem.value.date) {
        const isOverTime = curCartItem.value.isOverTime
        if (isOverTime) {
          controls.formItem.time.timeSelect = false
          controls.formItem.time.overTimeSelect = true
        } else {
          controls.formItem.time.timeSelect = true
          controls.formItem.time.overTimeSelect = false
        }
      }

      if (props.hideClassTicketSelector) {
        controls.formItem.classTickets = false
      } else {
        if (curCartItem.value.time) {
          controls.formItem.classTickets = true
        }
      }

      if (props.hideDateTimeSelector) {
        controls.formItem.date = false
        controls.formItem.time.timeSelect = false
        controls.formItem.time.overTimeSelect = false
        controls.formItem.isOverTIme = false
      }

      return controls
    })

    // ?? 顯示的服務類型選項
    const serviceTypeConfigList = computed(() => {
      const omitList = []
      if (!isShopOperation.value) {
        if (!checkAction('admin.resourceItem.page')) {
          omitList.push('resourceService', 'humanAndResourceService')
        }
      }
      return omit(serviceTypeConfig, omitList)
    })

    // ?? 顯示的服務人員選項
    const serviceUnitIOptions = computed(() => {
      const serviceType = curCartItem.value.serviceType
      if (serviceType === serviceTypeConfig.resourceService.value) return []

      const base = cloneDeep(resourceData.serviceUnits)

      const useNotSpecify = get(configData.reservation, 'useNotSpecify')
      if (isShopOperation.value) return base
      if (useNotSpecify) {
        base.unshift({
          id: 'notSpecify',
          name: '由不指定人員分配',
        })
      }

      base.unshift({
        id: 'all',
        name: '由所有服務人員分配',
      })
      return base
    })

    // ?? 顯示的服務人員選項的提示
    const serviceUnitSelectHint = computed(() => {
      const useNotSpecify = get(configData.reservation, 'useNotSpecify')
      const notSpecifyRule = get(configData.reservation, 'notSpecifyRule')
      const selectUnitId = get(curCartItem.value.serviceUnit, 'id')
      if (!includes(['notSpecify', 'all'], selectUnitId)) return { content: '', color: 'var(--base)' }

      if (useNotSpecify) {
        const rule = get(notSpecifyRulesConfig, `${notSpecifyRule}.label`)
        return { content: `分配規則預設為「${rule}」如需修改請至基本參數設定 > 預約模組參數設定設置`, color: 'var(--base)' }
      } else if (!useNotSpecify) {
        if (selectUnitId === 'all') return { content: '分配規則預設為「依排序大小」如需修改請至基本參數設定 > 預約模組參數設定設置', color: 'var(--base)' }
        else if (selectUnitId === 'notSpecify') return { content: '未啟用不指定人員設定，請至基本參數設定 > 預約模組參數設定設置', color: 'var(--danger)' }
      }
      return { content: '', color: 'var(--base)' }
    })

    // ?? 顯示的服務項目選項
    const displayServiceOptions = computed(() => {
      const serviceType = curCartItem.value.serviceType
      if (curCartItem.value.isResourceService()) {
        if (serviceType === serviceTypeConfig.resourceService.value) {
          return sortOrder(filter(dataPlaceholder.services, (i) => !i.enableAppointmentUnit && i.enableResourceItem))
        } else if (serviceType === serviceTypeConfig.humanAndResourceService.value) {
          return sortOrder(filter(dataPlaceholder.services, (i) => i.enableAppointmentUnit && i.enableResourceItem))
        }
      }
      const serviceOptionList = filter(dataPlaceholder.services, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
      return sortOrder(serviceOptionList)
    })

    // ?? 顯示的服務設備選項
    const displayResourceOptions = computed(() => {
      const selectedService = curCartItem.value.getDetailData('service')
      if (!selectedService) return [] // 尚未選擇服務項目

      let availableResources = filter(get(selectedService, 'ResourceItems'), { enabled: true, isRemove: false })

      // 如果有子項目
      if (curCartItem.value.subService) {
        const subService = curCartItem.value.subService
        const subServiceResource = get(subService, 'ResourceItems', [])
        if (!isEmpty(subServiceResource)) {
          const subServiceResourceIds = map(subServiceResource, 'id')
          availableResources = filter(availableResources, (item) => includes(subServiceResourceIds, item.id))
        }
      } else {
        availableResources.unshift({
          id: 'all',
          name: '由所有設備分配',
        })
      }

      return sortOrder(availableResources)
    })

    // ?? 顯示的服務子項目選項
    const displaySubServiceOptions = computed(() => {
      const serviceType = curCartItem.value.serviceType
      const selectedService = curCartItem.value.getDetailData('service')
      const selectedServiceUnit = curCartItem.value.serviceUnit

      if (!selectedService) return [] // 尚未選擇服務項目
      if (!selectedService.enableSubService) return [] // 未啟用子項目

      if (serviceType === serviceTypeConfig.resourceService.value) return get(selectedService, 'AppointmentSubServices')
      else if (includes(['notSpecify', 'all'], get(selectedServiceUnit, 'id'))) return get(selectedService, 'AppointmentSubServices')

      const subServices = get(selectedService, 'AppointmentSubServices')
      return filter(subServices, (item) => {
        // 子服務有綁選擇的服務人員 或 子服務沒有綁定任何服務人員(代表所有服務人員都可以選)
        return find(item.AppointmentUnits, { id: get(selectedServiceUnit, 'id') }) || item.allAppointmentUnit
      })
    })

    // ?? 顯示的附加服務項目選項
    const displayAttachServiceOptions = computed(() => {
      return dataPlaceholder.attachedServices
    })

    // ?? 顯示的預估服務時數選項
    const displayBookingTimeOptions = computed(() => {
      let nums = []
      const serviceType = curCartItem.value.serviceType
      const shopTimeUnit = get(configData, 'reservation.timeUnit')
      const totalTime = curCartItem.value.getAppointmentTotalTime()

      if (serviceType === serviceTypeConfig.humanService.value) {
        const times = generateNumbers(0, 600, shopTimeUnit)
        times.push(totalTime)
        nums = [...new Set(times.sort((a, b) => a - b))]
      } else if (includes([
        serviceTypeConfig.resourceService.value,
        serviceTypeConfig.humanAndResourceService.value,
      ], serviceType)) {
        const times = generateNumbers(0, totalTime + shopTimeUnit, shopTimeUnit)
        times.push(totalTime)
        nums = [...new Set(filter(times.sort((a, b) => a - b), (item) => item <= totalTime))]
      }
      return nums
    })

    // ?? 顯示的加班時段選項
    const displayOvertimeTimeOptions = computed(() => {
      const offset = get(configData.reservation, 'timeUnitOffset')
      const timeUnit = get(configData.reservation, 'timeUnit')
      const selectedDate = formatDate(curCartItem.value.date, 'YYYY/MM/DD')
      const times = generateFormatPeriod({ gap: timeUnit, offset, maxEnd: 1440 })
      return map(times, (time) => {
        return {
          label: time,
          value: dayjs(`${selectedDate} ${time}`).toDate(),
        }
      })
    })

    const datePickerOptions = ref({
      disabledDate: (date) => {
        const isOverTime = curCartItem.value.isOverTime
        if (isOverTime) return false
        const d = dayjs(date).format('YYYY/MM/DD')
        const nowTime = dayjs().format('YYYY/MM/DD')
        return !find(dataPlaceholder.availableDate, (item) => item === d) || dayjs(d).isBefore(nowTime, 'date')
      },
    })

    // ?? 顯示的堂票選項
    const displayClassTicketOptions = computed(() => {
      const selectedService = curCartItem.value.getDetailData('service')
      const appointmentDateTime = curCartItem.value.time
      if (!selectedService) return []
      if (!appointmentDateTime) return []

      // 過濾已過期或無可用次數的堂票
      const availableClassTickets = filter(memberData.classTickets, (item) => {
        if (!item.availableUseTimes) return false
        if (item.isExp) {
          const expDate = item.exp
          if (!expDate) return true
          // 檢查堂票過期時間是否大於預約時段
          else if (dayjs(expDate).isAfter(dayjs(appointmentDateTime), 'date')) return true
        } else {
          return true
        }
      })

      // 過濾會員針對該服務適用的堂票
      const serviceClassTicketIds = map(selectedService.ClassTickets, 'id')
      const classTickets = map(availableClassTickets, 'ClassTicketId')
      const intersectionClassTickets = intersection(serviceClassTicketIds, uniq(classTickets))
      if (!intersectionClassTickets.length) return [] // 會員沒有可用於服務的堂票

      const finalAvailablesClassTickets = filter(availableClassTickets, (item) => includes(intersectionClassTickets, item.ClassTicketId))
      const preCartItem = []
      const curCartItemIdx = findIndex(cart.value, { id: curCartItem.value.nanoId })
      for (let i = 0; i < curCartItemIdx; i++) {
        preCartItem.push(get(cart.value, `[${i}].cartItem`))
      }

      const formatedOptions = map(finalAvailablesClassTickets, (item) => {
        // 此張堂票已經被使用的次數
        const preUseTimes = reduce(preCartItem, (sum, i) => {
          const used = find(i.classTickets, { id: item.id })
          return sum + (used ? 1 : 0)
        }, 0)

        const expDate = item.exp ? formatDate(item.exp) : ''
        let label = `${item.name} 剩餘${item.availableUseTimes - preUseTimes}張`
        if (expDate) label = `${label} （期限：${expDate}）`

        return {
          ...item,
          label,
          disabled: item.availableUseTimes - preUseTimes <= 0,
          expDate,
        }
      })

      // 排序堂票，將 disabled: true 的選項排到最後，expDate 快過期的排在前面
      formatedOptions.sort((a, b) => {
        if (a.disabled && !b.disabled) return 1
        if (!a.disabled && b.disabled) return -1
        if (a.expDate && b.expDate) {
          if (dayjs(a.expDate).isBefore(b.expDate)) return -1
          if (dayjs(a.expDate).isAfter(b.expDate)) return 1
        }
        return 0
      })

      return formatedOptions
    })
    // ?? 顯示的可用時段
    const displayAvailableTimes = computed(() => {
      const now = dayjs()
      const times = map(filter(dataPlaceholder.availableTimes, (item) => {
        return dayjs(item).isSame(curCartItem.value.date, 'date')
      }), i => {
        return {
          label: dayjs(i).format('HH:mm'),
          value: i,
        }
      })

      const availableTimes = filter(times, (item) => {
        return dayjs(item.value).isAfter(now, 'minute')
      })

      return availableTimes
    })
    // => 取得服務詳情
    const findServiceDetail = async (service) => {
      const [res, err] = await FindService({
        shopId: shopId.value,
        id: service.id,
      })
      if (err) {
        window.$message.error(err.msg)
        return null
      }
      return res
    }
    // => 取得服務人員詳情
    const findServiceUnitDetail = async (unitId) => {
      const [res, err] = await apiFormatAdaptor(FindServiceUnit)({
        shopId: shopId.value,
        id: unitId,
      })

      if (err) {
        window.$message.error(err)
        return null
      }
      return res
    }
    // => 取得可預約時間
    const getAvailableTimes = async ({ start, end }) => {
      const selectedServiceUnitId = get(curCartItem.value.serviceUnit, 'id')
      const serviceType = curCartItem.value.serviceType
      if (serviceType === serviceTypeConfig.humanService.value) {
        if (!selectedServiceUnitId) return
      }

      const [res] = await GetAvailableTimes({
        shopId: shopId.value,
        ...curCartItem.value.getAvailableTimesPayload({
          start: start.toDate(),
          end: end.toDate(),
          notSpecifyUnits: get(configData, 'reservation.NotSpecifyUnits'),
          peopleCount: context.peopleCount,
          preReservations: getCartItemPreReservationsPayload(curCartItem.value.nanoId),
        }),
      })

      const list = []
      // 過濾已經超過當前時間的時間
      const availableTimes = filter(res.times, (item) => {
        return dayjs(item).isAfter(dayjs(), 'minute')
      })
      forEach(availableTimes, (item) => {
        list.push(dayjs(item).format('YYYY/MM/DD'))
      })
      dataPlaceholder.availableDate = Array.from(new Set(list))
      dataPlaceholder.availableTimes = res.times
    }
    // => 更新可選預約日期
    const getDateOptions = async () => {
      await nextTick()
      let dateStart
      let dateEnd

      if (get(datePickerRef.value, 'picker')) {
        const year = get(datePickerRef.value, 'picker.year')
        const month = get(datePickerRef.value, 'picker.month')
        const { start, end } = getMonth(year, month + 1)
        dateStart = start
        dateEnd = end
      } else {
        const { start, end } = thisMonth()
        dateStart = start
        dateEnd = end
      }

      await getAvailableTimes({ start: dayjs(dateStart), end: dayjs(dateEnd) })

      const pre = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
      )
      const next = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
      )
      const preYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
      )
      const nextYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
      )

      const task = async () => {
        dataPlaceholder.availableDate = []
        const year = get(datePickerRef.value, 'picker.year')
        const month = get(datePickerRef.value, 'picker.month')
        const { start, end } = getMonth(year, month + 1)

        // await this.getAvailableDate(start, end)
        await getAvailableTimes({ start: dayjs(start), end: dayjs(end) })
      }
      pre.removeEventListener('click', task)
      next.removeEventListener('click', task)
      preYear.removeEventListener('click', task)
      nextYear.removeEventListener('click', task)

      pre.addEventListener('click', task)
      next.addEventListener('click', task)
      preYear.addEventListener('click', task)
      nextYear.addEventListener('click', task)
    }
    // => 更新預估服務時數
    const updateBookingTime = async () => {
      await nextTick()
      if (curCartItem.value.serviceType === serviceTypeConfig.humanService.value) {
        curCartItem.value.bookingTime = curCartItem.value.getAppointmentTotalTime()
      } else if (curCartItem.value.isResourceService()) {
        curCartItem.value.resourceBookingTime = curCartItem.value.getAppointmentTotalTime()
      }
    }
    // => 當服務性質變動時
    const onServiceTypeChange = async (serviceType) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      dataPlaceholder.services = []
      curCartItem.value.resetDetailData()
      curCartItem.value.resetData()
      await nextTick()
      setTimeout(() => {
        curCartItem.value.serviceType = serviceType
        if (curCartItem.value.isResourceService()) {
          dataPlaceholder.services = cloneDeep(resourceData.services)
        }

        // 為了解決 formItem 元件快速切換渲染，error msg 不會消失問題
        setTimeout(async () => {
          loading.coreFlow = true
          await clearAllFormFieldsValidate()
          await nextTick()
          loading.coreFlow = false
        }, 0)
      }, 0)
    }
    // => 當服務人員變動時
    const onServiceUnitChange = async (serviceUnit) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      dataPlaceholder.services = []
      curCartItem.value.resetDetailData()
      curCartItem.value.resetData([
        'service',
        'subService',
        'attachedServices',
        'resources',
        'date',
        'time',
        'classTickets',
        'bookingTime',
        'resourceBookingTime',
      ])
      if (!serviceUnit) return
      if (includes(['notSpecify', 'all'], serviceUnit.id)) {
        dataPlaceholder.services = cloneDeep(resourceData.services)
      } else {
        const serviceUnitData = await findServiceUnitDetail(serviceUnit.id)
        if (serviceUnitData) {
          curCartItem.value.setDetailData('serviceUnit', serviceUnitData)
          dataPlaceholder.services = sortOrder(serviceUnitData.AppointmentServices)
        }
      }
    }
    // => 當服務項目變動時
    const onServiceChange = async (service) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetDetailData()
      curCartItem.value.resetData([
        'attachedServices',
        'subService',
        'resources',
        'date',
        'time',
        'classTickets',
        'bookingTime',
        'resourceBookingTime',
      ])
      // formData.totalTime = totalTime.value
      // formData.resourceTotalTime = totalTime.value

      updateBookingTime()
      if (!service) return
      const serviceData = await findServiceDetail(service)
      if (serviceData) {
        curCartItem.value.setDetailData('service', serviceData)
        dataPlaceholder.attachedServices = sortOrder(serviceData.AppointmentServiceAttaches)
      }
    }
    // => 當服務子項目變動時
    const onSubServiceChange = async (subService) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['attachedServices', 'time', 'date', 'classTickets', 'resources'])
      curCartItem.value.resetDetailData(['reservation'])
      updateBookingTime()
    }
    // => 當設備變動時
    const onResourceChange = (resources) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['time', 'date', 'classTickets'])
      curCartItem.value.resetDetailData(['reservation'])
      updateBookingTime()
    }
    // => 當附加服務變動時
    const onAttachedServicesChange = (attachedServices) => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['time', 'date', 'classTickets'])
      curCartItem.value.resetDetailData(['reservation'])
      updateBookingTime()
    }
    // => 當是否為加班時段變動時
    const onIsOverTimeChange = () => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['time', 'date', 'classTickets'])
      curCartItem.value.resetDetailData(['reservation'])
    }
    // => 當日期變動時
    const onDateChange = () => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['time', 'classTickets'])
      curCartItem.value.resetDetailData(['reservation'])
    }
    // => 當時間變動時
    const onTimeChange = async () => {
      if (context.appointmentMode === appointmentModeType.batchOrder) {
        pruneAfterCartItem(curCartItem.value.nanoId)
      }
      curCartItem.value.resetData(['classTickets'])
      curCartItem.value.resetDetailData(['reservation'])
      const [res, err] = await CheckReservation({
        shopId: shopId.value,
        ...curCartItem.value.getPreDetailByStartPayload({
          notSpecifyUnits: get(configData.reservation, 'NotSpecifyUnits'),
          preReservations: getCartItemPreReservationsPayload(curCartItem.value.nanoId),
          peopleCount: context.peopleCount,
        }),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      curCartItem.value.setDetailData('reservation', res)
      setTimeout(async () => {
        await nextTick()
        await defaultSelectClassTicket({
          displayClassTicketOptions: displayClassTicketOptions.value,
          cartItem: curCartItem.value,
        })
        await calculateCheckoutCashbackLimit()
      }, 0)
    }
    // => 當堂票變動時
    const onClassTicketChange = async () => {
      curCartItem.value.resetDetailData(['reservation'])
      const [res, err] = await CheckReservation({
        shopId: shopId.value,
        ...curCartItem.value.getPreDetailByStartPayload({
          notSpecifyUnits: get(configData.reservation, 'NotSpecifyUnits'),
          preReservations: getCartItemPreReservationsPayload(curCartItem.value.nanoId),
          peopleCount: context.peopleCount,
        }),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      curCartItem.value.setDetailData('reservation', res)
      setTimeout(async () => {
        await nextTick()
        await calculateCheckoutCashbackLimit()
      }, 0)
    }
    const calculateCheckoutCashbackLimit = async () => {
      const [res, err] = await CalculateCheckoutCashbackLimit({
        shopId: shopId.value,
        memberId: get(context.member, 'id'),
        reservations: map(composeAllCartItemReservations.value, (item) =>
          omit(item, ['start']),
        ),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      context.discount.cashback.limit = res.limit
    }

    onMounted(() => {
      updateCartItemData(props.cartItem.nanoId, 'formRef', formRef)
    })

    return {
      loading,
      formRef,
      displayData,
      showControl,
      dataPlaceholder,
      serviceTypeConfigList,
      onServiceTypeChange,
      context,
      getDateOptions,
      onServiceUnitChange,
      onSubServiceChange,
      onDateChange,
      onIsOverTimeChange,
      onAttachedServicesChange,
      onResourceChange,
      onTimeChange,
      serviceUnitIOptions,
      serviceUnitSelectHint,
      onServiceChange,
      displayBookingTimeOptions,
      displayOvertimeTimeOptions,
      displayAttachServiceOptions,
      displayServiceOptions,
      displayResourceOptions,
      displaySubServiceOptions,
      datePickerOptions,
      displayClassTicketOptions,
      displayAvailableTimes,
      curCartItem,
      datePickerRef,
      onClassTicketChange,
      formRules,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-form-item__label) {
  @apply flex;
}
</style>
