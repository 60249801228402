<template>
  <div class="shifts-calendar-setting">
    <PageTitle
      title="預約行事曆"
      btn="新增"
      btn2="顯示設定"
      btnFeature="admin.appointmentOrder.create"
      @btnClick="$router.push({ name: 'NewReservationCreate' })"
      @btn2Click="displaySettingDialog = true"
    />

    <FiltersContainer>
      <ServiceUnitSearch
        :model.sync="search.unit"
        testName="search_unit"
        :styling="checkAction('admin.resourceItem.page') ? 'full-input': ''"
        @change="refresh"
      />
      <ServiceSearch
        :model.sync="search.service"
        testName="search_service"
        @change="refresh"
      />
      <BaseElSelect
        v-model="search.status"
        testName="search_status"
        placeholder="搜尋預約狀態"
        clearable
        @change="showAllSchedule()"
      >
        <BaseElSelectOption
          v-for="(item, index) in ['cancel', 'complete', 'wait', 'absence']"
          :key="`status-${index}`"
          :label="statusContent(item)"
          :value="item"
        />
      </BaseElSelect>
      <BaseElInput
        v-model="search.code"
        testName="search_code"
        clearable
        placeholder="搜尋訂單編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
      <ResourceSearch
        v-if="checkAction('admin.resourceItem.page')"
        testName="search_resource"
        class="flex-grow-1"
        :model.sync="search.resource"
        @change="refresh"
      />
    </FiltersContainer>

    <p class="display-content-hint">
      目前顯示的內容是：<span v-if="!loading">{{ displayContentHint() }}</span>
    </p>

    <section v-loading="loading" class="calendar-container">
      <FullCalendar v-if="show" ref="calendar" :options="showCalendar" />
    </section>

    <!-- 預約資訊側邊彈窗 -->
    <ReservationOrderDetail
      v-if="modifyDialog"
      :orderId="selectOrderId"
      @close="modifyDialog=false"
      @cancel="onCancel"
      @refresh="refresh"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="警示"
      :content="deleteContent"
      width="40%"
      cancelBtnString="返回"
      @close="deleteDialog = false"
      @delete="cancelReservation(), deleteDialog=false"
    />

    <ReservationCalendarDisplaySettingModal
      v-if="displaySettingDialog"
      @close="displaySettingDialog = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ReservationOrderDetail from '@/components/Reservation/ReservationOrderDetail/ReservationOrderDetail.vue'
import {
  GetReservationSimple,
  GetReservationCount,
  FindReservation,
  UpdateReservationOrderStatus,
} from '@/api/reservation'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { FindMember } from '@/api/member'
import { statusTranslate } from '@/utils/helper'
import MixinFunc from '@/components/MixinFunc'
import ServiceUnitSearch from '@/components/Search/ServiceUnitSearch.vue'
import ServiceSearch from '@/components/Search/ServiceSearch.vue'
import ResourceSearch from '@/components/Search/ResourceSearch.vue'
import ReservationCalendarDisplaySettingModal from './components/ReservationCalendarDisplaySettingModal.vue'
import dayjs from '@/lib/dayjs'
import { get } from 'lodash'
import { useDisplaySettings } from '@/use/displaySettings'
import { displayContentHintConfig, displayColorHintConfig } from '@/config/reservationCalendar'
import { usePermissions } from '@/use/permissions'
import { useFetch } from '@/use/fetch'

export default {
  name: 'ReservationCalendar',
  components: {
    FullCalendar,
    DeleteDialog,
    ServiceUnitSearch,
    ResourceSearch,
    ServiceSearch,
    ReservationOrderDetail,
    ReservationCalendarDisplaySettingModal,
  },
  mixins: [MixinFunc],
  setup (props) {
    const { checkAction } = usePermissions()
    const { fetchAll } = useFetch()
    const { getDisplaySettings, displaySettings } = useDisplaySettings('reservationCalendar', {
      displayContent: 'serviceUnit-service',
      displayColor: 'order-status',
    })
    const displayContentHint = () => {
      const showLabels = []
      const displayContent = get(displaySettings.value, 'displayContent')
      const displayColor = get(displaySettings.value, 'displayColor')
      if (!displayContent) showLabels.push(get(displayContentHintConfig, 'serviceUnit-service.label'))
      if (displayContent) showLabels.push(get(displayContentHintConfig, `${displayContent}.label`))
      if (!displayColor) showLabels.push(get(displayColorHintConfig, 'order-status.label'))
      if (displayColor) showLabels.push(get(displayColorHintConfig, `${displayColor}.label`))
      return showLabels.join('、')
    }

    const useOrderStatusColor = (status) => {
      const colors = {
        cancel: '#F2697A',
        wait: '#E4A65F',
        complete: '#B5C93E',
      }
      return get(colors, status)
    }

    const setDataTestid = () => {
      const nextMonthBtn = get(Array.from(document.getElementsByClassName('fc-next-button')), [0])
      const preMonthBtn = get(Array.from(document.getElementsByClassName('fc-prev-button')), [0])
      const calendarTitle = get(Array.from(document.getElementsByClassName('fc-toolbar-title')), [0])

      if (nextMonthBtn) {
        nextMonthBtn.setAttribute('data-testid', 'next-month-btn')
      }
      if (preMonthBtn) {
        preMonthBtn.setAttribute('data-testid', 'prev-month-btn')
      }
      if (calendarTitle) {
        calendarTitle.setAttribute('data-testid', 'calendar-title')
      }
    }

    return { fetchAll, getDisplaySettings, displaySettings, displayContentHint, useOrderStatusColor, checkAction, setDataTestid }
  },

  data () {
    return {
      deleteContent: '',
      show: true,
      loading: false,
      modifyDialog: false,
      createDialog: false,
      deleteDialog: false,
      displaySettingDialog: false,
      showModify: false,
      showUserInfo: false,
      userInfo: null,
      modifyType: '',
      selectOrderId: null,

      reservationsCount: 0,
      reservationsList: [],

      selecReservation: {
        AppointmentService: {},
        AppointmentUnit: {},
        AppointmentServiceAttaches: [],
      },

      // 當前月曆顯示的日期區間
      calendarRange: {
        start: null,
        end: null,
      },

      showCalendar: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        eventDidMount: (e) => {
          if (e.el)e.el.setAttribute('data-testid', `calendar-event-${e.event?.title}`)
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        buttonText: {
          month: '月檢視',
          week: '週檢視',
          day: '天檢視',
          list: '列表',
        },
        initialView: 'dayGridMonth',
        weekends: true,
        height: '100%',
        selectable: true,
        displayEventTime: false,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        dateClick: this.showCalendarDateClick,
        eventClick: this.modifyReservation,
        events: [],

        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
        // 處理月曆 Toolbar 按鈕操控事件
        customButtons: {
          prev: {
            click: () => {
              this.$refs.calendar.getApi().prev()
              this.changeCalendar()
            },
          },
          next: {
            click: () => {
              this.$refs.calendar.getApi().next()
              this.changeCalendar()
            },
          },
          today: {
            text: '今日',
            click: () => {
              this.$refs.calendar.getApi().today()
              this.changeCalendar()
            },
          },
          timeGridWeek: {
            text: '週檢視',
            click: () => {
              this.$refs.calendar.getApi().changeView('timeGridWeek')
              this.changeCalendar()
            },
          },
        },
      },

      search: {
        date: null,
        userName: '',
        unit: '',
        service: '',
        status: '',
        code: '',
        resource: '',
      },
    }
  },

  computed: {
    ...mapGetters([
      'shop',
    ]),
    activeStart () {
      return this.$refs.calendar.getApi().view.activeStart
    },
    activeEnd () {
      return this.$refs.calendar.getApi().view.activeEnd
    },

    attachServices () {
      if (this.selecReservation.AppointmentServiceAttaches.length === 0) return [{ name: '無', id: 0 }]
      return this.selecReservation.AppointmentServiceAttaches
    },

    memberTags () {
      const mTags = this.userInfo.MTags
      const sTags = this.userInfo.SystemMTag
      if (mTags.length === 0 && this.userInfo.SystemMTag.length === 0) return [{ name: '無', id: 0 }]
      const list = []
      mTags.forEach(item => {
        list.push(item)
      })
      sTags.forEach(item => {
        list.push(item)
      })
      return list
    },

    // 處理搜尋排班班表區間資料
    calendarRangeString () {
      const format = 'YYYY-MM-DD'
      const { start, end } = this.calendarRange
      let dayStart, dayEnd
      if (start && end) {
        dayStart = start.format(format)
        dayEnd = end.format(format)
      } else {
        dayStart = dayjs().startOf('month').format(format)
        dayEnd = dayjs().endOf('month').format(format)
      }
      return { dayStart, dayEnd }
    },

    sidebar () { return this.$store.state.app.sidebar },
  },

  watch: {
    sidebar () {
      // const api = this.$refs.calendar.getApi()
      // api.render()
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
      console.log('resize')
    },
  },

  async mounted () {
    this.calendarRange = this.getCalendarDate()
    this.setDataTestid()
    await this.refresh()

    window.gotoDate = (date) => {
      const api = this.$refs.calendar.getApi()
      api.gotoDate(date)
      this.changeCalendar()
    }
  },
  beforeDestroy () {
    delete window.gotoDate
  },

  methods: {
    searchDate (data) {
      this.$refs.calendar.getApi().gotoDate(data)
    },
    statusContent: (status) => statusTranslate(status),
    onCancel (data) {
      this.selecReservation = data
      const orders = get(data, 'AppointmentOrder.AppointmentReservations')
      console.log(orders)
      const orderCode = get(data, 'AppointmentOrder.code')
      // const numberCodes = map(orders, 'numberCode')
      // const totalOrde = max(numberCodes)
      // this.deleteDialog = true
      if (!orders) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }
      if (!orders.length || orders.length === 1) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }

      const ordersCount = orders.length

      this.deleteContent = `訂單編號 ${orderCode} 有 ${ordersCount} 筆預約紀錄，將同時取消 ${ordersCount} 筆預約紀錄。`
      this.deleteDialog = true
    },
    //= > 取得月曆日期
    getCalendarDate () {
      const calendarApi = this.$refs.calendar.getApi()
      calendarApi.getDate()
      const date = dayjs(calendarApi.getDate())
      return {
        start: date.startOf('month').startOf('week'),
        end: date.endOf('month').endOf('week'),
      }
    },

    //= > 切換月曆(前後)
    async changeCalendar () {
      const prevDayStart = this.calendarRangeString.dayStart
      this.calendarRange = this.getCalendarDate()
      const currentDayStart = this.calendarRangeString.dayStart
      if (prevDayStart === currentDayStart) return
      await this.refresh()
    },

    async refresh () {
      this.getDisplaySettings()
      await this.getReservation()
      await this.getReservationCount()
      await this.showAllSchedule()
    },

    modify (type) {
      this.showModify = true
      this.modifyType = type
    },

    async modifyReservation (e) {
      this.modifyDialog = true
      this.selectOrderId = e.event.extendedProps.id
      // const res = await this.findReservation(e.event.extendedProps.id)
      // this.selecReservation = res
      // await this.findMember()
    },

    //= > 取得預約
    async getReservation () {
      this.loading = true
      this.reservationsList = []
      const payload = {
        shopId: this.shop,
        start: 0,
        dateStart: this.search.date ? dayjs(this.search.date).toDate() : dayjs(this.calendarRangeString.dayStart).subtract(7, 'day').toDate(), // 七天前
        dateEnd: this.search.date ? dayjs(this.search.date).add(1, 'day').toDate() : dayjs(this.calendarRangeString.dayEnd).add(8, 'day').toDate(), // 七天後
        userName: this.search.userName ? this.search.userName.trim() : undefined,
        AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
        AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
        ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
        code: (this.search.code === '') ? undefined : this.search.code,
      }

      await this.fetchAll(GetReservationSimple, payload, (res) => {
        this.loading = false
        this.reservationsList = res
      }, (err) => {
        this.loading = false
        this.$message.error({
          message: err || err.message,
        })
      }, { limit: 500 })
    },

    //= > 取得預約總數
    async getReservationCount () {
      try {
        this.reservationsCount = await GetReservationCount({
          shopId: this.shop,
          dateStart: (this.search.date === null) ? dayjs(this.calendarRangeString.dayStart).subtract(7, 'day').toDate() : dayjs(this.search.date).toDate(),
          dateEnd: (this.search.date === null) ? dayjs(this.calendarRangeString.dayEnd).add(8, 'day').toDate() : dayjs(this.search.date).add(1, 'day').toDate(),
          userName: (this.search.userName === '') ? undefined : this.search.userName.trim(),
          AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
          AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
          ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
          code: (this.search.code === '') ? undefined : this.search.code,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findReservation (id) {
      const res = await FindReservation({
        shopId: this.shop,
        id,
      })
      return res
    },

    async findMember () {
      if (this.selecReservation.Member === null) return
      const res = await FindMember({
        shopId: this.shop,
        id: this.selecReservation.Member.id,
      })
      console.log('findMember', res)
      this.userInfo = res
    },

    showAllSchedule () {
      this.showCalendar.events = []
      const onFilterStatus = this.search.status !== ''
      const onFilterUnit = this.search.unit !== ''
      let reservationsList = JSON.parse(JSON.stringify(this.reservationsList))
      console.log('reservationsList', reservationsList)
      if (onFilterStatus) {
        reservationsList = reservationsList.filter(item => get(item, 'status') === this.search.status)
      }
      reservationsList.forEach(item => {
        const status = get(item, 'status')
        let color = ''

        // 顯示顏色
        const displayColor = get(this.displaySettings, 'displayColor')
        if (displayColor === 'order-status') color = this.useOrderStatusColor(status)
        if (displayColor === 'service-unit') color = get(item, 'unitColor', 'gray')

        // 時段
        const timeStart = dayjs(item.start).format('YYYY-MM-DD HH:mm')
        const timeEnd = dayjs(item.end).format('YYYY-MM-DD HH:mm')

        const isEndSameDay = dayjs(timeEnd).isSame(dayjs(timeStart), 'date')
        const crossDate = dayjs(item.start).add(1, 'day').get('date')

        // 顯示資訊內容
        const displayContent = get(this.displaySettings, 'displayContent')

        const formatedDate = dayjs(timeStart).format('YYYY-MM-DD')
        let title = `${item.unitName} ${item.serviceName}`
        let className = `${formatedDate}-${item.unitName}-${item.serviceName}`
        const visitorTitle = item.origin === 'pubApt' ? '(訪客)' : ''
        if (displayContent === 'serviceUnit-service') {
          title = `${visitorTitle} ${item.unitName || '-'} ${item.serviceName}`
          className = `${formatedDate}-${visitorTitle}-${item.unitName}-${item.serviceName}`
        }
        if (displayContent === 'member-service') {
          title = `${visitorTitle} ${item.userName || '-'} ${item.serviceName}`
          className = `${formatedDate}-${visitorTitle}-${item.userName}-${item.serviceName}`
        }
        if (displayContent === 'resource-service') {
          title = `${visitorTitle} ${item.resourceName || '-'} ${item.serviceName}`
          className = `${formatedDate}-${visitorTitle}-${item.resourceName}-${item.serviceName}`
        }

        const startPeriod = dayjs(timeStart).format('HH:mm')
        const endPeriod = dayjs(timeEnd).format('HH:mm')
        let displayTitle = `${startPeriod} - ${endPeriod} ${title}`
        if (!isEndSameDay) displayTitle = `${startPeriod} - ${crossDate}日 ${endPeriod} ${title}`

        this.createCalendarEvent({
          title: displayTitle,
          timeStart,
          timeEnd: isEndSameDay ? timeEnd : dayjs(timeEnd).subtract(24, 'h').format('YYYY-MM-DD HH:mm'),
          allDay: false,
          props: item,
          color,
          className,
        })
      })
    },

    //= > 創建月曆事件
    createCalendarEvent ({ title, timeStart, timeEnd, props, color, className }) {
      const data = {
        title,
        start: timeStart,
        end: timeEnd,
        allDay: false,
        display: 'list-item',
        extendedProps: props,
        backgroundColor: color,
        className,
      }

      this.showCalendar.events.push(data)
    },

    //= > 刪除(取消)預約
    async cancelReservation () {
      console.log('cancelReservation', get(this.selecReservation, 'AppointmentOrder.id'))
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.selecReservation, 'AppointmentOrder.id'),
        status: 'cancel',
      })

      if (err) return this.$message.error(err)
      this.$message.warning('取消預約成功')
      this.modifyDialog = false
      this.refresh()
    },
  },
}
</script>

<style scoped lang="scss">
header {
  margin-right: 3rem;
}

section {
  flex: 1;
}

.calendar-container {
  height: 100vh;
}

.display-content-hint {
  @apply text-primary-100 font-medium mb-[20px];
}
</style>
