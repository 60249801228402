<template>
  <div class="shifts-calendar-setting">
    <PageTitle
      title="排班行事曆"
      btn="編輯排班班表"
      btn2="關閉時段"
      cyBtn1="edit-shift-schedule-btn"
      @btnClick="$router.push({
        name: 'ShiftsCalendar'
      })"
      @btn2Click="togglePeriodDialog = true"
    />

    <FiltersContainer>
      <ServicesUnitSelect
        testName="search_unit"
        :model.sync="search.unit"
        @change="showAllSchedule()"
      />
    </FiltersContainer>

    <section v-loading="loading" class="flex-1">
      <FullCalendar v-if="show" ref="showCalendar" class="h-full block" :options="showCalendar" />
      <div style="height: 800px" />
    </section>

    <!-- 月曆單一排班點擊 Dialog -->
    <EditSingleShiftEventModal
      v-if="updateDialog"
      :selectSchedule="selectSchedule"
      :shopScheduleList="shopScheduleList"
      :shopShiftsClassList="shopShiftsClassList"
      @close="updateDialog = false"
      @refresh="refresh"
    />

    <TogglePeriodDialog
      v-if="togglePeriodDialog"
      title="編輯關閉時段"
      width="440px"
      @close="togglePeriodDialog = false"
    />
  </div>
</template>

<script>
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { GetServiceUnit } from '@/api/serviceUnit'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import { timesConvert } from '@/utils/helper'
import shiftCalendar from '@/mixin/shiftCalendar'
import EditSingleShiftEventModal from './components/EditSingleShiftEventModal.vue'
import dayjs from '@/lib/dayjs'
import { useFullCalendar } from '@/use/useFullCalendar'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
import { useFetch } from '@/use/fetch'
import { apiFormatAdaptor } from '@/utils/api'

export default {
  name: 'ShiftsCalendarSetting',
  components: {
    FullCalendar,
    ServicesUnitSelect,
    TogglePeriodDialog,
    EditSingleShiftEventModal,
  },
  mixins: [shiftCalendar],
  setup () {
    const { formatEventTitle, formatEventTime } = useFullCalendar()
    const { fetchAll } = useFetch()
    const setDataTestid = () => {
      const nextMonthBtn = get(Array.from(document.getElementsByClassName('fc-next-button')), [0])
      const preMonthBtn = get(Array.from(document.getElementsByClassName('fc-prev-button')), [0])
      const calendarTitle = get(Array.from(document.getElementsByClassName('fc-toolbar-title')), [0])

      if (nextMonthBtn) {
        nextMonthBtn.setAttribute('data-testid', 'next-month-btn')
      }
      if (preMonthBtn) {
        preMonthBtn.setAttribute('data-testid', 'prev-month-btn')
      }
      if (calendarTitle) {
        calendarTitle.setAttribute('data-testid', 'calendar-title')
      }
    }
    return { formatEventTitle, formatEventTime, setDataTestid, fetchAll }
  },

  data () {
    return {
      show: true,
      loading: false,
      togglePeriodDialog: false,
      updateDialog: false, // 編輯月曆單一排班
      selectScheduleClass: null,
      selectServiceUnit: null,

      shopShiftsClassList: [], // 店家班別
      shopScheduleList: [], // 店家排班表
      serviceUnitList: [], // 服務人員列表
      selectSchedule: null,

      // 傳給月曆的資料
      showCalendar: {
        eventDidMount: (e) => {
          if (e.el)e.el.setAttribute('data-testid', `calendar-event-${e.event?.title}`)
        },
        longPressDelay: 100,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        buttonText: {
          month: '月檢視',
          week: '週檢視',
          day: '天檢視',
          list: '列表',
        },
        // 處理月曆 Toolbar 按鈕操控事件
        customButtons: {
          prev: {
            click: () => {
              this.$refs.showCalendar.getApi().prev()
              this.changeCalendar()
            },
          },
          next: {
            click: () => {
              this.$refs.showCalendar.getApi().next()
              this.changeCalendar()
            },
          },
          today: {
            text: '今日',
            click: () => {
              this.$refs.showCalendar.getApi().today()
              this.changeCalendar()
            },
          },
        },
        initialView: 'dayGridMonth',
        weekends: true,

        selectable: true,
        displayEventTime: false,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        locale: 'en-gb',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
        },
        eventClick: this.showCalendarEventClick,
        // 所有排班班表紀錄
        events: [],
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
      },

      search: {
        unit: '',
      },
    }
  },

  computed: {
    ...mapGetters(['shop']),
    sidebar () { return this.$store.state.app.sidebar },
  },

  watch: {
    sidebar () {
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
      console.log('resize')
    },
  },

  async mounted () {
    this.calendarRange = this.getCalendarDate(this.$refs.showCalendar.getApi())
    await this.getServiceUnit() //= > 取得分店人員
    await this.getShopShiftsClass() //= > 取得班別
    await this.getShiftsSchedule() //= > 取得排班表
    await this.showAllSchedule() //= > 顯示店家當前所有班表

    await this.$nextTick()
    this.setDataTestid()

    window.gotoDate = (date) => {
      const calendarApi = this.$refs.showCalendar.getApi()
      calendarApi.gotoDate(date)
      this.changeCalendar()
    }
  },

  beforeDestroy () {
    delete window.gotoDate
  },

  methods: {
    async refresh () {
      try {
        this.loading = true
        await this.getShiftsSchedule()
        await this.showAllSchedule()
        this.loading = false
      } catch (error) {
        this.$message.error(error)
      }
    },

    showCalendarEventClick (data) {
      this.selectScheduleClass =
        data.event.extendedProps.AppointmentScheduleDayId
      this.selectSchedule = data.event.extendedProps
      this.updateDialog = true
    },

    // => 顯示店家當前所有班表
    async showAllSchedule () {
      this.showCalendar.events = []
      let schedules = JSON.parse(JSON.stringify(this.shopScheduleList))
      schedules = schedules.filter((item) => !item.AppointmentUnit.isRemove)
      if (this.search.unit !== '') {
        schedules = schedules.filter((item) => {
          return item.AppointmentUnitId === this.search.unit.id
        })
      }
      schedules.forEach(async (item) => {
        // 取得班表的班別
        const shiftsClass = this.shopShiftsClassList.find(
          (shiftsClass) => shiftsClass.id === item.AppointmentScheduleDayId,
        )
        if (!shiftsClass) return
        const times = shiftsClass.AppointmentScheduleDayTimes
        const color = item.AppointmentScheduleDay.color

        times.forEach((time) => {
          this.createCalendarEvent({
            title: item.AppointmentUnit.name,
            day: item.day,
            timeStart: time.start,
            timeEnd: time.end,
            mode: 'all',
            props: item,
            color,
            className: `${formatDate(item.day, 'YYYY-MM-DD')}-${item.AppointmentUnit.name}`,
          })
        })
      })
    },

    //= > 創建月曆事件
    createCalendarEvent ({
      title,
      day,
      timeStart,
      timeEnd,
      props,
      color,
      className,
    }) {
      const {
        endTime, startTime, startPeriod, endPeriod,
        isEndSameDay,
        isStartSameDay,
      } = this.formatEventTime({ day, timeStart, timeEnd })
      const displayTitle = this.formatEventTitle({
        date: day,
        start: startPeriod,
        end: endPeriod,
        title,
        isEndSameDay,
        isStartSameDay,
      })

      const data = {
        title: displayTitle,
        start: startTime,
        end: endTime,
        display: 'list-item',
        allDay: false,
        extendedProps: props,
        backgroundColor: color,
        className,
      }

      this.showCalendar.events.push(data)
    },

    //= > 取得服務人員
    async getServiceUnit () {
      this.loading = true
      await this.fetchAll(apiFormatAdaptor(GetServiceUnit), {
        shopId: this.shop,
      }, (res) => {
        this.serviceUnitList = res
      })
      this.loading = false
    },

    //= > 切換月曆(前後)
    async changeCalendar () {
      const prevDayStart = this.calendarRangeString.dayStart
      this.calendarRange = this.getCalendarDate(this.$refs.showCalendar.getApi())
      const currentDayStart = this.calendarRangeString.dayStart
      if (prevDayStart === currentDayStart) return
      await this.getShiftsSchedule()
      this.showAllSchedule()
    },
  },
}
</script>

<style scoped lang="postcss">
::v-deep .fc .fc-scroller-liquid-absolute {
  display: block;
  position:  unset !important;
  overflow: hidden !important;
  /* height: 100%; */
}

.shifts-calendar-setting {
  @apply pb-[400px] mb-[400px] h-full flex flex-col;
}
header {
  margin-right: 3rem;
}

section {
  flex: 1;
}
</style>
