<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <BaseElFormItem :label="displayData.formItem.comment">
      <BaseElInput
        v-model="context.comment"
        type="textarea"
        show-word-limit
        maxlength="150"
        :autosize="{ minRows: 5, maxRows: 6}"
        placeholder="請輸入"
      />
    </BaseElFormItem>
    <BaseElFormItem :label="displayData.formItem.shopNote">
      <BaseElInput
        v-model="context.shopNote"
        type="textarea"
        show-word-limit
        maxlength="150"
        :autosize="{ minRows: 5, maxRows: 6}"
        placeholder="請輸入"
      />
    </BaseElFormItem>
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppointmentOtherInfoBlock',
  setup (props, { emit }) {
    const { context } = useCreateAappointment()
    const displayData = computed(() => {
      return {
        formItem: {
          comment: '預約者備註',
          shopNote: '店家筆記',
        },
      }
    })
    return { displayData, context }
  },
})
</script>

<style lang="postcss" scoped>

</style>
