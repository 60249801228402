<template>
  <div class="sidebar">
    <BaseElInput v-if="useSidebarSearch" v-model="search" style="width: 100%" placeholder="搜尋頁面" clearable />
    <div class="sidebar-wrapper">
      <div
        v-if="sidebarPage === 2"
        id="current-shop-btn"
        class="branch"
        @click="routeTo('/branch-shop-options'), setPage()"
      >
        <span class="material-icons">chevron_left</span>
        <span class="name" :data-testid="`active-shop-${shopName}`">{{ shopName }}</span>
      </div>

      <!-- 組織頁面 -->
      <el-menu
        v-show="sidebarPage === 1"
        :default-active="activeMenu"
        :unique-opened="false"
        :collapse-transition="false"

        mode="vertical"
      >
        <sidebar-item
          v-for="route in orgAdminRoutes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :haveUnreadAnnouncements="haveUnreadAnnouncements"
        />
      </el-menu>

      <!-- 動態頁面 -->
      <el-menu
        v-show="sidebarPage === 2 && shop"
        :default-active="activeMenu"
        :unique-opened="false"
        :collapse-transition="false"

        mode="vertical"
      >
        <sidebar-item
          v-for="route in availableAsyncRoutes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :haveUnreadAnnouncements="haveUnreadAnnouncements"
        />
      </el-menu>
    </div>

    <div
      v-if="paramsRoutes.length && sidebarPage === 2"
      class="setting-btn-container"
    >
      <el-menu mode="vertical" class="w-full">
        <router-link to="/parameter-settings" data-cy="settings-btn">
          <el-menu-item class="settings-btn flex items-center">
            <span class="material-icons" style="font-size: 20px; margin-right: 8px">settings</span>
            <span>基本參數設定</span>
          </el-menu-item>
        </router-link>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import { checkFeature } from '@/store/modules/permission'
import { get, filter, includes, cloneDeep, lowerCase } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'Sidebar',
  components: {
    SidebarItem,
  },
  setup () {
    const search = ref(null)
    const haveUnreadAnnouncements = computed(() => get(store.state, 'app.haveUnreadAnnouncements'))
    const useSidebarSearch = computed(() => get(store.getters, 'app/useSidebarSearch'))

    const filterSearchRoute = (routes) => {
      const cloneRoutes = cloneDeep(routes)
      return filter(cloneRoutes, (route) => {
        if (includes(route.meta?.title, search.value) || includes(lowerCase(route.name), lowerCase(search.value))) {
          return route
        }
        if (route.children) {
          const childs = filterSearchRoute(route.children)
          if (childs.length) {
            route.children = childs
            return route
          }
        }
      })
    }
    return { search, filterSearchRoute, useSidebarSearch, haveUnreadAnnouncements }
  },

  computed: {
    ...mapGetters([
      'permissionRoutes',
      'orgAdminRoutes',
      'asyncRoutes',
      'shopName',
      'shop',
      'sidebarPage',
      'userFeatures',
      'sidebar',
      'appTheme',
    ]),

    paramsRoutes () {
      if (!this.asyncRoutes) return []
      if (this.asyncRoutes) {
        if (!this.asyncRoutes.length) return []
      }
      const routes = this.asyncRoutes.filter(route => route.name === 'Parameters')
      if (routes) {
        if (!routes.length) return []
      }
      const children = routes[0].children
      return filter(children, route => !route.hidden)
    },

    useParamsRoutes () {
      return checkFeature('userFeatures', 'admin.lineConfig.page')
    },

    isMobile () {
      return this.$store.state.app.isMobile
    },

    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },

    availableAsyncRoutes () {
      // ---- 是否過慮Homepage頁面
      if (this.appTheme === 'righttime') {
        return this.asyncRoutes.filter(i => i.name !== 'HomePage')
      }
      if (this.search) {
        return this.filterSearchRoute(this.asyncRoutes)
      }
      return this.asyncRoutes
    },
  },

  beforeMount () {
    const initSidebar = () => {
      const width = window.innerWidth
      if (width <= 1200) {
        this.$store.dispatch('app/setSidebar', false)
        this.$store.dispatch('app/setMobile', true)
      }
      if (width > 1200) {
        this.$store.dispatch('app/setSidebar', true)
        this.$store.dispatch('app/setMobile', false)
      }
    }
    initSidebar()
    window.addEventListener('resize', () => {
      if (this.$route.name === 'DailyView') return
      initSidebar()
    })
  },

  methods: {
    routeTo (path) {
      this.$router.push(path)
    },
    async setPage () {
      const page = this.sidebarPage === 1 ? 2 : 1
      await this.$store.dispatch('app/setSidebarPage', page)
    },
    clearBranch () {
      this.$store.commit('user/SET_NOW_SHOP', null)
    },
  },

})
</script>

<style lang="scss" scoped>
.branch {
  @apply flex items-center cursor-pointer py-[10px] text-primary-100 px-[15px];
  @apply border border-primary-100 rounded-bl-sm rounded-tr-sm mb-[12px];
  .title {
    @apply font-bold text-[16px] overflow-hidden whitespace-nowrap overflow-ellipsis;
  }
  .name {
    @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
  }
}

.branch:hover {
  @apply bg-primary-100 text-white;
}

// 側邊欄 menu item
::v-deep .el-menu {
  @apply border-r-0 bg-transparent;
}

$views-padding: 60px;
$offset-padding: 300px;
$sidebar-width: 260px;

.sidebar {
  @apply border border-r-gray-40 bg-[#F7F8FC];
  transition: ease-in-out 0.3s;
}

.sidebar::-webkit-scrollbar {
  @apply hidden;
}

.sidebar-wrapper {
  @apply py-[20px] px-[15px] flex flex-col overflow-x-hidden overflow-y-auto flex-1 whitespace-nowrap;
}

.sidebar-wrapper::-webkit-scrollbar {
  @apply hidden;
}

@media screen and (min-width: 1200px) {
  .sidebar {
    @apply relative w-0 h-full overflow-x-hidden flex flex-col ;
  }
}

@media screen and (max-width: 1200px) {
  .sidebar {
    @apply absolute w-[260px] left-[-300px] top-[80px] bottom-0 flex-shrink-0 flex flex-col z-[500];
  }

  .sidebar-wrapper {
    @apply h-full;
  }
}

.setting-btn-container {
  @apply bg-[#F7F8FC] px-[15px] relative;
}
.setting-btn-container::after {
  content: '';
  width: 95%;
  @apply h-[1px] bg-gray-40 absolute top-0 left-0 right-0 m-auto;
}

.open {
  @apply left-0 ;
}

.settings-btn.el-menu-item {
  @apply py-[6px] h-[36px] rounded-sm my-[14px];
  line-height: unset;
}

.settings-btn.el-menu-item:hover {
  @apply bg-primary-100 text-white h-[36px] font-bold;
}
</style>
