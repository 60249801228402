<template>
  <div class="reservation-time-space">
    <PageTitle
      title="預約空檔查詢"
      btn2="關閉時段"
      btn="新增"
      fontSize="20"
      btnFeature="admin.appointmentOrder.create"
      @btnClick="$router.push({ name: 'NewReservationCreate' })"
      @btn2Click="togglePeriodDialog = true"
    />
    <FiltersContainer>
      <!-- <ServicesUnitSelect :model.sync="unit" :showAll="true" @change="refresh"/> -->
      <el-date-picker
        v-model="searchDate"
        editable
        format="yyyy-MM-dd"
        placeholder="選擇日期"
        @change="refresh"
      />
    </FiltersContainer>
    <section v-if="times">
      <BaseTable
        v-if="salesUnitList.length === 0"
        class="test"
        :data="salesUnitList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="服務人員" prop="name" fixed="left" />
      </BaseTable>

      <BaseTable
        v-if="salesUnitList.length > 0"
        v-loading="loading"
        :data="salesUnitList"
        empty-text="暫無數據"
      >
        <BaseElTableColumn
          label="服務人員"
          width="120px"
          align="center"
          prop="name"
          fixed="left"
        />
        <BaseElTableColumn
          v-for="num in showColumn()"
          :key="num"
          align="center"
          min-width="120px"
          :label="num"
          prop="availableTimes.times"
        >
          <template slot-scope="scope">
            <div class="block" :class="periodBlock(scope).status">
              {{ trans(periodBlock(scope)) }}
            </div>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesUnitCount"
        @pageChange="refresh"
      />
    </section>

    <!-- 關閉時段 Dialog -->
    <TogglePeriodDialog v-if="togglePeriodDialog" title="編輯關閉時段" width="440px" @close="togglePeriodDialog = false" @updated="refresh" />

    <!-- 預約新增 Dialog -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GetServiceUnit, GetServiceUnitCount } from '@/api/serviceUnit'
import { timesConvert } from '@/utils/helper'
import { FindReservationParameter } from '@/api/reservation'
import { GetPeriods } from '@/api/togglePeriod'
import { pageStartIndex } from '@/utils/table'
import EmptyBlock from '@/components/EmptyBlock.vue'
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import dayjs from '@/lib/dayjs'
import { getDay } from '@/utils/date'
import { map, min, max, filter } from 'lodash'

export default {
  name: 'ReservationTimeSpace',
  components: {
    EmptyBlock,
    TogglePeriodDialog,
  },

  data: () => ({
    searchDate: new Date(),
    show: [],
    unit: '',
    times: [],
    loading: false,
    unitTimesList: [],
    salesUnitList: [],
    salesUnitCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    reservationConfig: {},
    togglePeriodDialog: false,
  }),

  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  async mounted () {
    await this.refresh()
    this.genTimes()
  },

  methods: {
    trans ({ status, availableVolume }) {
      const dict = {
        empty: '無排班',
        available: '可預約',
        booking: '已預約',
        ban: '已關閉',
      }

      return availableVolume ? `${dict[status]}(${availableVolume})` : dict[status]
    },

    showColumn () {
      if (!this.show.length) return this.times
      const timeToNum = (time) => {
        const hm = time.split(':')
        return hm[0] * 60 + Number(hm[1])
      }
      const temp = map(this.show, (item) => {
        return timeToNum(item)
      })
      const minPeriod = min(temp)
      const maxPeriod = max(temp)

      return filter(this.times, (item) => {
        const num = timeToNum(item)
        return num >= minPeriod && num <= maxPeriod
      })
    },

    periodBlock (scope) {
      if (!scope.row.availableTimes) return { status: '' }
      const times = scope.row.availableTimes.times
      const label = scope.column.label
      if (!times || times.length === 0) return { status: 'empty' }
      const res = times.find((item) => {
        const time = dayjs(item.start).format('HH:mm')
        if (time === '00:00' && label === '24:00') return true
        return time === label
      })
      if (!res) return { status: 'empty' }
      else return { status: res.status, availableVolume: res.availableVolume }
    },

    async refresh () {
      this.loading = true
      await this.findReservationParameter()
      await this.getServiceUnit()
      await this.getServiceUnitCount()
      await this.findServiceUnit()
      this.loading = false
    },

    genTimes () {
      const end = 1440
      const unit = this.reservationConfig.timeUnit
      const offset = this.reservationConfig.timeUnitOffset
      let time = 0
      while (time + offset < end) {
        this.times.push(timesConvert(time + offset))
        time += unit
      }
      // if(this.times.includes('00:00')) {
      //   this.times.shift()
      //   this.times.push('24:00')
      // }
    },

    async getServiceUnit () {
      try {
        this.salesUnitList = await GetServiceUnit({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async getServiceUnitCount () {
      try {
        this.salesUnitCount = await GetServiceUnitCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findServiceUnit () {
      this.show = []
      for (const unit of this.salesUnitList) {
        this.$set(unit, 'availableTimes', [])
        try {
          const { start, end } = getDay(this.searchDate)
          const res = await GetPeriods({
            shopId: this.shop,
            appointmentUnitId: unit.id,
            // start: new Date(`${dayjs(this.searchDate).format("YYYY/MM/DD")} 00:00`),
            // end: new Date(`${dayjs(this.searchDate).add(1, "d").format("YYYY/MM/DD")} 00:00`),
            start,
            end,
          })
          this.$set(unit, 'availableTimes', res)
          // const times = map(res.times, (item) => {
          //   return dayjs(item).unix()
          // })
          this.show.push(
            ...map(res.times, (item) => {
              return dayjs(item.start).format('HH:mm')
            }),
          )
        } catch (error) {
          console.log(error)
          // this.$message.error(error)
        }
      }

      this.show = Array.from(new Set(this.show))
    },

    async findReservationParameter () {
      try {
        this.reservationConfig = await FindReservationParameter({
          shopId: this.shop,
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.block {
  border-radius: 8px;
  padding: 5px;
  text-align: center;
}

.empty {
  color: gray;
  background: lightgray;
}

.ban {
  color: white;
  background: rgb(253, 102, 139);
}

.booking {
  background: rgb(112, 192, 216);
}

.available {
  color: gray;
  background: rgb(189, 233, 122);
}
</style>
