import { render, staticRenderFns } from "./OrderInfoBlock.vue?vue&type=template&id=0a2d04ec&scoped=true"
import script from "./OrderInfoBlock.vue?vue&type=script&lang=js"
export * from "./OrderInfoBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a2d04ec",
  null
  
)

export default component.exports