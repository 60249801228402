<template>
  <BaseDialog title="編輯設備預約設定" :btn1Loading="loading" @confirm="onConfirm" @close="$emit('close')">
    <BaseElForm ref="formRef" :model="formData" style="margin-bottom: 40px" label-position="top">
      <BaseElFormItem label="跳過選擇設備" prop="clientSkipSelect">
        <BaseElSwitch v-model="formData.clientSkipSelect" active-text="開" inactive-text="關" />
      </BaseElFormItem>

      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>同時空檔設備分配規則</p>
      </div>
      <BaseElFormItem prop="assignRule">
        <BaseElSelect v-model="formData.assignRule" class="form-select">
          <BaseElSelectOption
            v-for="item in assignRuleConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <p class="text-[#636363] text-[14px]">
        預約數優先：依照前後14天內預約數，較低者優先，相同則隨機。<br>
        <!-- 不指定預約數優先：依照前後14天內來自不指定的預約數，較低者優先，相同則隨機。<br> -->
        排序優先：依照服務人員的排序，較低者優先。
      </p>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { UpdateResourceConfig } from '@/api/resource'
import { assignRuleConfig } from '@/config/resource'
export default defineComponent({
  name: 'EditResourceConfigModal',
  components: {
    BaseDialog,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { shopId } = useShop()
    initFormData({
      clientSkipSelect: false,
      assignRule: '',
    })

    const onConfirm = async () => {
      loading.value = true
      const payload = {
        shopId: shopId.value,
        assignRule: formData.assignRule,
        clientSkipSelect: formData.clientSkipSelect,
      }
      console.log(payload)
      const [, err] = await UpdateResourceConfig(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onBeforeMount(() => {
      formData.assignRule = props.configData.assignRule
      formData.clientSkipSelect = props.configData.clientSkipSelect
    })

    return {
      formRef,
      formData,
      loading,
      checkForm,
      onConfirm,
      assignRuleConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
